//ハンバーガーメニューclick


const hamburgerLineTop = document.getElementById('hamburgerLineTop');
const hamburgerLineMiddle = document.getElementById('hamburgerLineMiddle');
const hamburgerLineBottom = document.getElementById('hamburgerLineBottom');
const hamburgerArea = document.getElementById('hamburgerArea');
const body = document.querySelector('body');

export default {	
	hamburgerOpenFunc(){
		body.style.overflowY = 'hidden';
		hamburgerArea.style.height = window.innerHeight + 'px';
		hamburgerLineTop.style.transform = 'rotate(-45deg)';
		hamburgerLineTop.style.top = '1px';
		hamburgerLineMiddle.style.opacity = '0';
		hamburgerLineBottom.style.transform = 'rotate(45deg)';
		hamburgerLineBottom.style.bottom = '1px';
		hamburgerArea.style.transform = 'translateX(0)';
	},

	hamburgerCloseFunc(){
		body.style.overflowY = 'visible';
		hamburgerLineTop.style.transform = 'rotate(0)';
		hamburgerLineTop.style.top = '-0.59rem';
		hamburgerLineMiddle.style.opacity = '1';
		hamburgerLineBottom.style.transform = 'rotate(0)';
		hamburgerLineBottom.style.bottom = '-0.59rem';
		hamburgerArea.style.transform = 'translateX(100%)';
	}
};