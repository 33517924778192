// scssファイルをjsファイルにimportする
import "@scss/style.scss";

import hamburgerOpenFunc from "./modules/_hamburgerClick";
import hamburgerCloseFunc from "./modules/_hamburgerClick";
(() => {
	let openClose = 'close';
	const hamburgerButton = document.getElementById('hamburgerButton');
	const hamburgerNav = document.querySelectorAll('#hamburgerNav');
	hamburgerButton.addEventListener('click',function(){
		if(openClose === 'close'){
			hamburgerOpenFunc.hamburgerOpenFunc();
			openClose = 'open';
		}else if(openClose === 'open'){
			hamburgerCloseFunc.hamburgerCloseFunc();
			openClose = 'close';
		}
	});
})();