export default {
	titleAnimDelay(){
		const dataCategory = document.querySelectorAll('[data-category]');
		for(let i = 0;i < dataCategory.length;i++){
			const oneLetter = document.querySelectorAll(`[data-titleAnim=${dataCategory[i].dataset.category}]`);
			for(let j = 0;j < oneLetter.length;j++){
				oneLetter[j].style.animationDelay = `${j / 50}s`;
			}
		}
	}
};