// scssファイルをjsファイルにimportする
import "@scss/style.scss";

//mvの流れる画像
function mvFlowImageFunc(){
	let imagePosH = [];
	let imagePosW = [];
	const image = document.querySelectorAll('#mvFlowImage');
	const imageHeight = image[0].clientHeight;
	const imageWidth = image[0].clientWidth;
	const media = window.matchMedia('(max-width:960px)');
	for(let i = 0;i < image.length;i++){
		imagePosH[i] = imageHeight * i;
		image[i].style.bottom = imageHeight * i + 'px';
	}
	for(let i = 0;i < image.length;i++){
		imagePosW[i] = imageWidth * i;
		image[i].style.bottom = imageWidth * i + 'px';
	}
	function loopLg(){
		for(let j = 0;j < image.length;j++){
			imagePosH[j] += -2;
			image[j].style.bottom = imagePosH[j] + 'px';
			if(imagePosH[j] < -imageHeight){
				imagePosH[j] = imageHeight * (image.length - 1);
			}
		}
	}
	function loopSm(){
		for(let j = 0;j < image.length;j++){
			imagePosW[j] += -2;
			image[j].style.left = imagePosW[j] + 'px';
			if(imagePosW[j] < -imageWidth){
				imagePosW[j] = imageWidth * (image.length - 1);
			}
		}
	}
	function mainLoop(){
		if(media.matches){
			for(let i = 0;i < image.length;i++){
				image[i].style.bottom = '0';
			}
			window.requestAnimationFrame(loopSm);
		}else{
			for(let i = 0;i < image.length;i++){
				image[i].style.left = '0';
			}
			window.requestAnimationFrame(loopLg);
		}
		window.requestAnimationFrame(mainLoop);
	}
	mainLoop();
}
window.addEventListener('load',mvFlowImageFunc);
let lastInnerWidth = window.innerWidth ;
window.addEventListener( "resize", function () {
	if ( lastInnerWidth != window.innerWidth ) {
		lastInnerWidth = window.innerWidth ;
		mvFlowImageFunc();
	}
} ) ;



//ハンバーガーメニューclick
import hamburgerOpenFunc from "./modules/_hamburgerClick";
import hamburgerCloseFunc from "./modules/_hamburgerClick";
(() => {
	let openClose = 'close';
	const hamburgerButton = document.getElementById('hamburgerButton');
	const hamburgerNav = document.querySelectorAll('#hamburgerNav');
	hamburgerButton.addEventListener('click',function(){
		if(openClose === 'close'){
			hamburgerOpenFunc.hamburgerOpenFunc();
			openClose = 'open';
		}else if(openClose === 'open'){
			hamburgerCloseFunc.hamburgerCloseFunc();
			openClose = 'close';
		}
	});
	hamburgerNav.forEach(button => {
		button.addEventListener('click',function(){
			if(openClose === 'close'){
				hamburgerOpenFunc.hamburgerOpenFunc();
				openClose = 'open';
			}else if(openClose === 'open'){
				hamburgerCloseFunc.hamburgerCloseFunc();
				openClose = 'close';
			}
		});
	});
})();


//textをspanで分割
import splitText from "./modules/_splitText";

splitText.splitText();

//textInitPos
import titleAnimDelay from "./modules/_titleAnimDelay";

titleAnimDelay.titleAnimDelay();



//ビューポート侵入時のアニメーション
(() => {
	const options = {
		root: null,
		rootMargin:"-20% 0px",
		threshold: 0,
	};
	//titleのアニメーション
	(() => {
		const letter = document.querySelectorAll('#intersect');
		const observer = new IntersectionObserver(moveElement, options);
		letter.forEach(intersectLetter => {
			observer.observe(intersectLetter);
		});

		function moveElement(entries){
			entries.forEach(element => {
				if(element.isIntersecting){
					element = element.target;
					element.style.animationName = 'titleUpAnim';
				}
			});
		}
	})();
})();



//flow line
(() => {
	const options = {
		root: null,
		rootMargin:"-20% 0px",
		threshold: 0,
	};
	const lineAnim = document.getElementById('lineAnim');
	const observer = new IntersectionObserver(lineExtend, options);
	observer.observe(lineAnim);

	function lineExtend(entryes){
		entryes.forEach(element => {
			if(element.isIntersecting){
				element = element.target;
				element.classList.add('c-animation-line');
			}
		});
	}
})();
