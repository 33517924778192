
export default {
	splitText(){
		const dataCategory = document.querySelectorAll('[data-category]');
		for(let i = 0;i < dataCategory.length;i++){
			const text = document.querySelectorAll(`[data-split=${dataCategory[i].dataset.category}]`);
			for(let j = 0;j < text.length;j++){
				const textInside = text[j].innerHTML;
				const splitedText = textInside.split('');
				let newText = [];
				for(let k = 0;k < splitedText.length;k++){
					newText += `<span class=c-title-anim-one-letter id=intersect data-titleAnim=${dataCategory[i].dataset.category}>${splitedText[k]}</span>`;
				}
				text[j].innerHTML = newText;
			}
		}
	}
};